body {
  --bg-color: #e0b2bd;
  --main-color: #fbf4b6;
  --sub-color: #b4e9ff;
  --sub-alt-color: #d29fab;
  --text-color: #6d5c6f;
}

.hb-text {
  color: var(--sub-color);

}

.song-text {
  color: var(--sub-color);
}

.notes-text {
  color: var(--sub-color);
}

.other-title {
  color: var(--main-color);
}

.my-nav-bar {
  background-color: var(--main-color);
  color: var(--text-color);
}

.my-nav-bar-off {
  background-color: var(--bg-color);
}

.my-icon {
  color: var(--sub-color);
  border-color: var(--sub-color);
  background-color: transparent;
}

video {
  width: 90%;
  height: auto;
  margin: 2vw;
}

.bg-theme {
  background-color: var(--bg-color);
}

.my-text-color {
  color: var(--text-color);
}

.page {
  min-height: 100vh;
}

.cell-text {
  color: var(--sub-color);
}

.cell {
  border-color: var(--main-color);
  border-style: solid;
  border-radius: 1rem;
}

.video-border {
  border-color: var(--sub-alt-color);
  border-style: solid;
  border-radius: 1rem;
}